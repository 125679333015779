.profile-photo {
    width: 360px;
    height: 360px;
}
.profile-page {
    .is-fetching {
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 500px;
    }
    label {
        margin: 0;
    }
    .player-info {
        & > div {
            width: 100%;
        }
    }
}
.save-profile{
    margin-top: 20px !important;
    width: 100%;
}
.show-stat{
    margin-bottom: 20px;
    display: block;
    text-align: right;
}
.profile.place-rating{
    margin: -40px 0px 30px 0px !important;
    z-index: 9;
}