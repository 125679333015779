
@media (max-width: 500px) {
    .footer-copyright{
        flex-direction: column;
        & > div{
            margin: 10px 0px;
        }
    }
}
.footer-links{
    padding: 5px 0px;
    text-align: center;
    font-size: 12px;
    a {
        color: #fff;
    }
}
.footer-copyright{
    flex-direction: column;
    & > div{
        margin: 10px 0px;
    }
}
.footer{
    background: unset !important;
    box-shadow: none !important;

    .footer-container{
        background: #2A3644;
        &.container{
            display: block;
            border-radius: 20px;
            margin-bottom: 20px;
        }
    }
}
.footer-contantcs{
    display: flex;
    justify-content: space-between;
    width: 100%;
}