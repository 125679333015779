.common-bg-container {
    // background-image: url("../../images/bg.png");
    width: 500px;
    height: 500px;
    position: fixed;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    opacity: 0.4;
    transform: rotate(10deg);
    left: 60px;
    bottom: 5%;
    z-index: -10;
}
.app-main-layout {
    margin: 30px auto;
    min-height: calc(100vh - 336px);
}
.page-scrolled{
    margin-top: 197px;
    @media (max-width: 500px) {
        .menu ul li a {
            margin-top: 261px;
        }
    }
}