body {
  margin-top: 20px;
  color: #1a202c;
  text-align: left;
  background-color: #f5f5f5;
  // background-image: url("../../../images/pattern-bg.png");
}

.card {
  box-shadow:
    0 1px 3px 0 rgba(0, 0, 0, 0.1),
    0 1px 2px 0 rgba(0, 0, 0, 0.06);
}

.breadcrumb {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #fff;
  border: 1px solid #e3e3e3;
  border-radius: 0.25rem;
}
.player-place-custom {
  font-size: 62px !important;
  color: #4e4e4e !important;

  &.no-activity {
    font-size: 29px !important;
  }
}
.stat-block {
  display: flex;
  justify-content: space-between;
  & > div:nth-child(2) {
    color: #808080;
    font-weight: 400;
  }
}
.stat-h4 {
  // margin-top: 20px;
  font-size: 14px;
  width: 100%;
}
.place-rating {
  display: flex;
  justify-content: center;
  position: relative;
  width: fit-content;
  margin: 35px auto 10px;
  & > p {
    display: flex;
    &.abs-rating {
      margin: 0;
      position: absolute;
      right: -50px;
      top: -20px;
      background: #e3e3e3;
      padding: 5px 10px;
      border-radius: 15px;
      border: 1px solid #646464;
      box-shadow: 2px -1px 5px 4px rgba(0, 0, 0, 0.1);
    }
    &.player-place-custom {
      text-shadow: 1px 1px 6px rgba(0, 0, 0, 1);
      color: #fff !important;
    }
  }
}
.player-card .list-group-flush,
.player-card .list-group-flush li {
  span {
    color: #808080;
    font-weight: 400;
  }
}
.user-racket {
  align-items: center !important;
  padding: 5px 0px;
  flex-direction: column;
  padding: 5px 10px;
}
.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  // background-color: #fff;
  background-clip: border-box;
  border: 0 solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  overflow: hidden;
  background: #fff;
  box-shadow: none;
  // border: 1px solid #0a0a0a;
  border-radius: 20px;
  .card-bg {
    background-image: url("../../../images/bg.png");
    width: 100%;
    height: 150%;
    position: absolute;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    opacity: 0.4;
    transform: rotate(10deg);
  }
}
.container-romb {
  border-radius: 20px;
}
.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1rem;

  h6 {
    font-weight: 700;
  }
}

.gutters-sm {
  margin-right: -8px;
  margin-left: -8px;
}

.gutters-sm > .col,
.gutters-sm > [class*="col-"] {
  padding-right: 8px;
  padding-left: 8px;
}
.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.bg-gray-300 {
  background-color: #e2e8f0;
}
.h-100 {
  height: 100% !important;
}
.last-3-events {
  background: #fff;
  border-radius: 20px;
  li {
    line-height: 40px;
    padding: 0px 10px !important;

    h6.mb-0 {
      font-size: 14px;
      color: #1a202c;
      font-weight: 400;
    }

    &:nth-child(2n) {
      background-color: #f9f9f9;
    }
  }
}
.shadow-none {
  box-shadow: none !important;
}
.react-multi-carousel-list {
  margin-bottom: 20px;
}
.chart-container {
  background: #fff;
  box-shadow: none;
  // margin-left: -40px;
  margin-bottom: 35px !important;
  padding: 20px 0px 30px 0px;
  &.d-none {
    display: none;
  }
  .recharts-legend-wrapper {
    position: relative !important;
  }
}
.player-photo {
  width: 100%;
  height: 360px;
  background-repeat: no-repeat;
  background-position: center 10%;
  background-size: cover;
  z-index: 9;
  border-radius: 100%;
  // border: 3px solid #1c426e;
  &.b-cotainer {
    // background-size: contain !important;
  }
}
.chart-container svg {
  overflow: visible;
}
.p2p-last-5-matches {
  display: flex;
  align-items: center;

  .p2p-score {
    margin-right: 20px;
    border-right: 1px solid #e3e3e3;
    padding: 5px 15px;
    height: 40px;
    display: flex;
    align-items: center;
  }
  .one-match {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-right: 15px;
    margin-top: 3px;
    margin: 0px 10px 0px 0px;
    padding: 0px 10px;
    &.green {
      background-color: #e9f4ee !important;
    }
    &.red {
      background-color: #ffd7d3 !important;
    }

    span {
      font-size: 14px;
    }
    > div {
      width: 15px;
      height: 15px;

      border-radius: 100%;
    }
  }
}
.list-group h6,
.list-widget h6 {
  font-weight: 700;
}
.list-widget {
  list-style-type: none;
  padding: 0;
  margin-bottom: 0px;
  a {
    font-weight: 700;
  }
  li:last-child {
    border-bottom: none;
  }
  li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #d1d1d1;
    padding: 0px 5px;

    .last-match-result {
      font-size: 22px;
      &.green {
        color: #0f8d3f !important;
      }
      &.red {
        color: #ff0000 !important;
      }
    }
  }
}
.w-100 {
  width: 100%;
}
.rounded-circle {
  border-radius: 10% !important;
}
// .text-secondary-custom {
//   font-weight: 700;
//   text-align: left;
//   color: #1a202c;
// }
.t-center {
  text-align: center;
  display: block;
}
.text-muted {
  color: #6c757d;
  font-size: 22px;
  font-weight: 700;
  margin: 15px;
}
.player-data .mt-3 {
  width: 100%;
}

h6.mb-0 {
  font-size: 14px;
  color: #1a202c;
}
.player-hand-hvat {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.player-card .player-verificated {
  top: 0px;
}
.player-verificated {
  position: absolute;
  top: 9px;
  left: 0px;
  margin: 0px;
  // background: #fff;
  width: 40px !important;
  height: 40px !important;
  border-radius: 100%;
  border-radius: 0px 0px 20px 20px;
  padding: 5px;
  background: #ff7d73;
  // border: 1px solid;

  &.profile-page-icon {
    border-radius: 100%;
    margin: 5px;
  }
}

.place-rating.details {
  margin-top: -60px !important;
  z-index: 9;
}
.player-amplua {
  margin: -10px 0px 0px 0px;
}
.list-group-item {
  flex-direction: row !important;
  border: none !important;
}
.next-info-block {
  border-top: 1px solid #cccccc;
  padding-top: 20px;
  margin-top: 20px;
}
.right-info-block {
  .mb-3 {
    margin-bottom: 20px !important;
    .card {
      background-color: unset !important;
    }
    & > h6 {
      color: #3a3941;
      text-transform: uppercase;
    }
  }
  .last-7-games {
    background-color: #fff;
  }
}
.last-matches-container {
  display: flex;
  @media (max-width: 500px) {
    max-width: 150px;
    overflow-y: scroll;
  }
}

.last-matches-player-name {
  max-width: 150px;
  a {
    color: #ff6355;
  }
  @media (max-width: 500px) {
    max-width: 65px;
  }
}
.games-opponent {
  // background-color: #fff;
  .games-filter {
    justify-content: space-between;
    display: flex;
    background-color: #fff;
  }
  width: 100%;
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 10px;
  .games-filter > div {
    width: 100%;
  }
  .opponent-container {
    h4 {
      text-align: center;
      margin-top: 10px;
      span {
        font-size: 12px;
        color: #ff0000;
        cursor: pointer;
      }
    }
    .opponent-line {
      background-color: #fff;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border: 1px solid #e3e3e3;
      padding: 10px 30px;
      border-radius: 20px;
      margin-bottom: 10px;
      .opponent-avatar {
        position: relative;
        .opponent-image {
          width: 100px;
          height: 100px;
          background-size: cover;
          border-radius: 100px;
        }
        .opponent-place {
          font-size: 30px;
          position: absolute;
          color: #4b4b4b;
          font-weight: 700;
          right: 0px;
          top: 0px;
          text-shadow: 1px 1px 6px rgb(0, 0, 0);
          color: #fff !important;
        }
        .opponent-rating {
          font-size: 20px;
          text-align: center;
          font-weight: 700;
          margin: 5px;
        }
      }
      .opponent-score {
        font-size: 50px;
        text-align: center;
        line-height: 50px;
      }
      .opponent-event {
        font-size: 15px;
        margin-top: 20px;
        text-align: center;
        min-width: 210px;
      }
      .opponent-delta {
        text-align: center;
        font-size: 24px;
        margin-top: 10px;
      }
    }
  }
}
.opponent-no-games {
  text-align: center;
  font-size: 17px;
  margin: 10px;
}
.spinner-container {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  height: 70px;
}
.games-places-line {
  margin-bottom: 10px;
  border-bottom: 1px solid #e3e3e3;
  padding-bottom: 5px;
  .show-history {
    cursor: pointer;
    width: 30px;
    height: 30px;
    margin-top: -4px;
    margin-left: 10px;
  }
}
.alert-to-see-matches {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  background: #dddddd;
  display: flex;
  align-items: center;
  border-radius: 10px;
  svg {
    margin-right: 12px;
    fill: #a94141;
  }
  div {
    text-align: center;
    font-style: italic;
  }
}
.places-container {
  padding: 10px;
  box-sizing: border-box;
}
.games-container{
  max-height: 400px;
  overflow-y: scroll;
}