.log-in {
    color: #fff;
    margin: 0px 45px;
    opacity: 0;
}

.exit-label {
    color: #fff;
    // margin-top: 8px;
    cursor: pointer;
}

.logo {
    @media screen and (max-width: 500px) {
        a {
            font-size: 22px;
        }
    }
}

.show-mobile-menu {
    display: block !important;
}

.auth-field {
    margin-bottom: 20px !important;

    & > div {
        width: 100%;
    }
}

.auth-exit-modal {
    z-index: 9999999 !important;

    div {
        overflow: unset;
    }
}

.my-header {
    display: flex;
    justify-content: center;

    @media screen and (min-width: 500px) {
        .col-md-12 {
            max-width: 50%;
        }
    }
}

.g-auth {
    // height: 65px;
    color: #1a202c;
    display: flex !important;
    // align-items: center;

    @media screen and (max-width: 500px) {
        padding: 5px 0px;

        // background-color: #f3f3f3;
        width: 100%;
        background-color: #fbfbfb;
        z-index: 99;
        box-shadow: 0px 8px 48px 3px rgba(000, 000, 000, 0.2);

        &.g-auth-no-entered {
            // margin-top: -30px;
            background: none;
            box-shadow: none;
            // margin-bottom: 20px;
        }
    }

    .g-auth-main-container {
        width: 100%;
        display: flex;

        .g-exit {
            cursor: pointer;
        }

        > div {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            padding: 5px 0px;
            height: unset !important;

            & > div {
                display: flex;
                font-size: 12px;
                // margin: 3px 0px;
                color: #fff;
            }
        }
    }
}

.auth-container {
    b {
        cursor: pointer;
    }
}

.edit-profile {
    font-size: 18px !important;
    cursor: pointer;
}

.notif-line {
    display: flex;
    justify-content: space-between;

    & > div {
        width: calc(100% / 4);
        word-wrap: break-word;
        border: 1px solid #e3e3e3;
        padding: 5px;

        &:first-child {
            width: 70%;
        }

        &:last-child {
            width: 30%;
        }
    }
}

.notif-icon {
    cursor: pointer;
}

.request-modal > div > div {
    max-width: 400px;
    width: 100%;
}

.request-modal {
    overflow-x: scroll;
}

.request-actions-icons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    svg {
        margin: 10px 0px;
        cursor: pointer;

        &:first-child {
            path {
                color: #0b9248;
            }
        }

        &:nth-child(2) {
            path {
                color: #8b0426;
            }
        }
    }
}

.header .col-md-12 {
    display: flex;
    justify-content: center;
}

@media screen and (max-width: 500px) {
    .header {
        .col-md-12 {
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
        }
    }
}

.profile-info {
    font-size: 12px !important;

    & > div {
        padding: 0px 5px;
    }
}
.fixed-header {
    position: fixed;
    top: 0px;
    width: 100%;
    z-index: 99;
    transition: 0.5s;
}
.new-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    a {
        color: #fff;
    }
}
// .mobile-g-auth{
//     display: none !important;
// }
.mobile-g-auth {
    display: none !important;
}
@media screen and (max-width: 500px) {
    .mobile-g-auth {
        display: flex !important;
        box-shadow: none;
        background: none !important;
    }
    .new-header{
        .g-auth{
            display: none !important;
        }
    }
    
    .g-auth .g-auth-main-container > div > div {
        font-size: 18px;
        color: #007bff !important;
    }
}
