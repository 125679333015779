.ads-container {
    background-color: #fff;
    .home-post {
        border: 1px solid #e3e3e3;
        margin: 0px 0px 10px;
        padding: 10px;
        p {
            color: #9a9a9a;
            height: 45px;
            display: -webkit-inline-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
            word-break: break-word;
            margin-bottom: 5px;
        }
        .home-post-title {
            font-size: 15px;
            margin-bottom: 10px;
        }
        .home-post-cover {
            width: 100%;
            min-height: 200px;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: top;
            margin-bottom: 10px;
        }
    }
    .add-ads-btn {
        margin: 20px auto;
    }
    .col-md-3 {
        float: left;
        padding: 0px 5px;
    }
}
.add-ads-container {
    padding: 5px 20px 0px 20px;
    width: 600px;
    height: 100%;
    @media (max-width: 500px) {
        width: 100% !important;
    }
    h3 {
        text-align: center;
    }
    .add-ads-btn.modal {
        margin: 20px auto;
        height: 60px;
    }
    label {
        margin: 0px;
        text-align: center;
    }
    .ads-type-container {
        width: 100%;
        .ads-type {
            display: flex;
            flex-direction: row;
            justify-content: center;
            width: 100%;
        }
    }
}
.close-modal {
    position: absolute;
    right: 0px;
    top: 0px;
    margin: 10px;
    font-size: 40px;
    cursor: pointer;
}
.form-control {
    border: none;
}
.ads-photo {
    margin: 0px 0px 20px;
    width: 100%;

    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 30px;
    &.min-height {
        height: 600px;
    }
}
.del-photo {
    margin: 0 auto;
    display: flex;
    width: 100%;
    justify-content: center;
    font-weight: 700;
    font-size: 20px;
    margin: 10px 0px 10px;
    cursor: pointer;
}
.hide-show-btn {
    margin: 0px;
}
.home-post-more {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
}
.price-container {
    display: flex;
    align-items: baseline;
    .product-price {
        font-size: 20px;
        color: #1A202C !important;
        margin-left: 5px;
    }
}
.hide-btn {
    background-color: #83180f !important;
}
.hide-ads-container {
    background-color: #e3e3e3;
}
.ads-remove-icon {
    background-color: #e98181;
    position: absolute;
    right: 0px;
    top: 0px;
    border-radius: 10px;
    margin: 5px 12px;
    cursor: pointer;
}
.no-content {
    display: flex;
    justify-content: center;
    min-height: 200px;
    align-items: center;
}
.click-more {
    cursor: pointer;
}
.no-ads {
    display: flex;
    min-height: 200px;
    justify-content: center;
    align-items: center;
    font-size: 20px;
}
.ads-expired{
    color: #ff0000;
    font-size: 11px;
    font-weight: 700;
}
