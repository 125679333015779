.new {
  background-image: url("../../images/labels/new-labels/new.png");

  &.tag {
    width: 37px;
    height: 17px;
  }
}

// .recomand{
//     background-image: url('../../images/labels/new-labels/recomand.png');
// }
.discount {
  background-image: url("../../images/labels/new-labels/discount.png");

  &.tag {
    width: 54px;
    height: 17px;
  }
}

.count-number {
  font-size: 16px;
  font-weight: 700;
}

.sales {
  background-image: url("../../images/labels/new-labels/sales.png");

  &.tag {
    width: 37px;
    height: 17px;
  }
}

.week {
  background-image: url("../../images/labels/new-labels/week.png");

  &.tag {
    width: 59px;
    height: 17px;
  }
}

.product-price {
  display: flex;
  justify-content: space-evenly;

  .price,
  .price-before-discount {
    line-height: 16px !important;
  }

  .price {
    color: #343434;
    margin-bottom: 10px;
  }
}

.percent-discount {
  border: 2px solid #000;
  margin: 0px 5px 5px;
  border-radius: 10px;
  background: #e6e6e6;
  padding: 0px 10px;
  font-weight: 700;
  border-top: none;
  border-right: none;
  height: 20px;
  color: #000;
  position: absolute;
  top: 0px;
  right: 0px;
}

.hot-deals .product-info .name {
  min-height: 30px;
  font-size: 12px;
  font-family: "Open Sans", sans-serif;
}

.add-to-cart {
  background-color: #1a202c;
  border-radius: 0px 0px 10px 10px;
}

.products {
  margin-right: -1px;
  margin: 7px;
  border-radius: 12px;
  // min-height: 350px;
  display: flex;
  // background: #fff;
  align-items: center;
  // border: 1px solid #1A202C;

  .product {
    width: 100%;

    &:hover {
      // box-shadow: -1px 1px 10px 10px rgba(19, 148, 55, 0.3);
    }
  }

  .product-description {
    color: #343434;
    text-align: right;
    min-height: 18px;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }

  .popup-description {
    cursor: pointer;

    &:hover {
      color: #ff3939;
    }
  }

  .show-description-popup {
    color: #343434;
    background-color: rgba(000, 000, 000, 0.8);
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 999;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .product-image {
    position: relative;
  }

  .close-icon {
    font-size: 20px;
    position: absolute;
    top: 0px;
    right: 0px;
    margin: 10px;
    font-weight: 700;
    cursor: pointer;

    &:hover {
      color: #a00006;
    }
  }
}
.mt-3 {
  width: 100%;
}
.event-statistic {
  display: flex;
  justify-content: center;
  flex-direction: column;

  .delta-plus,
  .delta-minus {
    margin-left: 5px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 5px;
    width: 100%;
    span {
      width: auto;
      display: block;
      font-size: 30px;
    }
  }
  .one-player-avatar .place {
    font-size: 24px;
  }
  .stat {
    display: flex;
    flex-direction: column;
    b {
      font-size: 18px;
      border-top: 1px solid #afafaf;
      padding: 5px;
    }
  }
}
.one-player-avatar {
  h4 {
    margin-bottom: 0px;
    line-height: 17px;
  }
  .player-photo {
    // border-right: 10px solid #FF7D73;
    height: 290px;
    border-radius: 0px 30px 0px 0px;
    position: relative;
    // border-top: 3px solid #e3e3e3;
    // border-top: 10px solid #FF7D73;
    // border: 1px solid #0a0a0a;

    // @media (max-width: 500px) {
    //   height: 350px !important;
    // }
  }
  .card-body {
    padding: 0px;
    // border: 1px solid #0a0a0a;
    border-radius: 0px 0px 20px 20px;
    // background: #fff;
    // box-shadow: 0px 24px 48px 0px #edeff2;
  }
  .text-muted {
    // margin: 5px 5px 0px 5px;
    font-size: 20px;
    color: #050505 !important;
    padding-right: 10px;
  }
  .place {
    font-size: 20px;
    display: block;
    width: 100%;
    text-align: right;
  }
  .is-current-user {
    background-color: #cef0ff;
    border-radius: 11px;
  }
}
.bottom-player-info {
  background-color: #fff;
  border-radius: 0px 0px 20px 20px;
  width: 100%;
}
.player-url {
  display: flex;
  flex-direction: column;
  // align-items: flex-end;
  // margin-bottom: 10px;
  width: 100%;

  margin-top: 10px;
  line-height: 16px;
  font-size: 16px;
  color: #333;
  font-weight: 700;
}
.photo-gradient {
  height: 60px;
  width: 100%;
  background: linear-gradient(0deg, white, transparent 70%);
  position: absolute;
  bottom: 0px;
}
h4 {
  &.first-name {
    font-size: 24px;
    margin-bottom: 5px;
  }
  &.last-name {
    font-size: 16px;
  }
}
.player-rating-container {
  display: block;
  width: 100%;
}
.amplua {
  font-size: 12px;
  margin-top: 5px;
  color: #333;
}
.player-name-place {
  width: 100%;
  padding: 5px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .place {
    margin: 0;
    text-align: left;
  }
  span {
    background: #ff7d73;
    color: #fff;
    padding: 0px 20px;
    border-radius: 0px 10px 10px 0px;
  }
}
.player-verificated {
  position: absolute;
  top: 10px;
  left: 1px;
  // background: #fff;
  width: 40px !important;
  height: 40px !important;
  border-radius: 100%;
  padding: 5px;
  // border: 1px solid;
  path {
    stroke: #fff;
    fill: #fff;
  }
}
.player-rating {
  margin: 0px;
  width: 103%;
  height: 100%;
}
.linear-border {
  background-image: url("../../images/line-border.png");
  background-repeat: no-repeat;
  width: 104%;
  height: 100%;
  /* right: -13px; */
  position: absolute;
  background-size: contain;
}
.place-league-container {
  display: flex;
  justify-content: space-between;
  padding: 5px 20px;
  .place-league {
    span {
      font-size: 12px;
    }
  }
}
.bottom-player-info {
  .best-line {
    width: 50px;
    min-width: unset;
    // margin: 0px;
    height: 2px;
    background-color: #ff7d73;
    margin: 5px 0px;
  }
}
