.home-page {
  .side-menu {
    .menu-item img {
      margin-top: -6px;
    }
  }
}

@media (max-width: 767px) {
  .home-page .side-menu .menu-item img {
    position: absolute;
    left: 20px;
  }

  .nav .menu-item {
    /* float: left; */
    width: 100%;
    padding: 0px;
    line-height: 21px;
    font-size: 22px;
    text-align: center;
    background-color: #fff;
    margin-right: 10px;
    border-radius: 10px;
    font-weight: 700;
    margin-bottom: 10px;
  }
}
.add-event-container {
  & > div {
    overflow: visible;
  }
}
.action-buttons {
  // &:hover
  & > div svg {
    opacity: 0.2;
  }
  & > div:hover svg {
    opacity: 1;
  }
  & > div:hover .pencil-icon:hover path {
    fill: #008a60;
  }
  & > div:hover .trash-icon:hover path {
    fill: #af0505;
  }
  svg {
    cursor: pointer;
    rect {
      fill: none;
    }
  }
}
.events.bg-white {
  background-color: #fff !important;
  box-shadow: 0px 0px 9px -4px rgba(0, 0, 0, 0.3);
  div[role="row"] {
    background-color: #fff !important;
  }
}
.events-container > div {
  align-items: center;
  justify-content: center;
  min-height: 200px;
  display: flex;
}
.league-title {
  text-align: center;
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 10px;
}
.place-line {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.league-container {
  border: 1px solid #e3e3e3;
  padding: 10px;
  margin-top: 10px;
  border-radius: 5px;
}
.filled-event{
  background-color: #e3e3e3;
  padding: 5px 10px;
}