.add-result-btn {
  margin: 20px auto !important;
  display: flex !important;
}
.result-line {
  border: 1px solid #e3e3e3;
  display: flex;
  align-items: center;
  padding: 10px 15px;
  margin: 0 0px -1px 0px !important;
  > div {
    padding: 0px !important;
  }
  b {
    font-weight: 700;
    font-size: 14px;
  }
  .action-icons {
    display: flex;
    justify-content: center;

    // &:hover
    svg {
      opacity: 0.2;
    }
    svg:hover {
      opacity: 1;
    }
    .pencil-icon:hover path {
      fill: #008a60;
    }
    .trash-icon:hover path {
      fill: #af0505;
    }
    svg {
      cursor: pointer;
      rect {
        fill: none;
      }
    }
  }
}
.edit-player-name {
  text-align: center;
  color: #3a3a3a;
}
.t-row {
  display: flex;
  flex-direction: column !important;
}
.error {
  color: #af0505;
  text-align: center;
  width: 100%;
  font-weight: 700;
  margin-top: 10px;
}
.set-score input {
  text-align: center;
  font-size: 16px;
  font-weight: 700;
}
.delta-plus {
  color: #0f8d3f;
  font-weight: 700;
  text-align: center;
  // font-size: 12px;
  // margin-left: 10px;
}
.t-center {
  text-align: center;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.delta-minus {
  color: #ff0000;
  font-weight: 700;
  text-align: center;
  // font-size: 12px;
  // margin-left: 10px;
}
.show-match-delta .t-row {
  display: flex;
  flex-direction: column;
  b {
    font-size: 18px;
  }
}
.recalc-rating-btn {
  display: flex;
  justify-content: flex-end;

  button {
    margin-bottom: 20px;
  }
}
.match-score b {
  font-size: 28px;
}
.delta-container {
  display: flex;
  justify-content: space-between;
  @media (max-width: 576px) {
    flex-direction: column !important;
    > div {
      max-width: 100%;
    }
  }

  label {
    margin: 0px;
  }
}
.who-win-container {
  display: flex !important;
  & > div {
    display: flex;
    justify-content: space-between;
    label {
      margin: 0;
    }
  }

  @media (max-width: 576px) {
    & > label {
      display: none;
    }
    & > div {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
}
.select-player-container {
  display: flex;
  align-items: center;
  width: 100%;
  & > div {
    width: 100%;
  }
}
.players-score {
}
.r-buttons-container {
  display: flex;
  padding: 0px !important;
  > div {
    justify-content: end;
    @media (max-width: 500px) {
      width: 100%;
      padding: 0px 30px;
      flex-direction: row;
      justify-content: space-around;
    }
  }
  &.a-center {
    display: flex;
    justify-content: center;
    > div {
      align-items: center;
      justify-content: space-around;
      display: flex;
      flex-direction: column;
      font-size: 24px;

      @media (max-width: 500px) {
        width: 100%;
        padding: 0px 30px;
        flex-direction: row;
        justify-content: space-around !important;
      }
    }
  }
  &.a-right {
    > div {
      @media (min-width: 501px) {
          justify-content: start;
      }
    
    }
  }
}
.quick-result{
  display: flex;
  justify-content: center;
  font-size: 50px;
  line-height: 50px;
}
.score-delta{
  display: flex;
  &.left{
   span{
    margin-right: 10px;
   }
  }
  &.right{
    span{
      margin-left: 10px !important;
     }
  }
}