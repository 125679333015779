// * {
//   font-family: "Source Code Pro", monospace !important;
// }
html {
  // background: url("../../images//patt.jpg");
}
body {
  margin: 0 !important;
}
.site-counter {
  position: fixed;
  left: 0px;
  bottom: 0px;
}
.notistack-SnackbarContainer{
  z-index: 99999999 !important;
}
// @media (max-width: 768px) {
//   .container,
//   .container-md,
//   .container-sm {
//     max-width: 100%;
//     min-width: 100% !important;
//   }
// }
