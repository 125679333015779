.home-page {
  .side-menu {
    .menu-item img {
      margin-top: -6px;
    }
  }
}
.admin-mode-icon{
  color: #42ab00;
}
@media (max-width: 767px) {
  .home-page .side-menu .menu-item img {
    position: absolute;
    left: 20px;
  }

  .nav .menu-item {
    /* float: left; */
    width: 100%;
    padding: 0px;
    line-height: 21px;
    font-size: 22px;
    text-align: center;
    background-color: #fff;
    margin-right: 10px;
    border-radius: 10px;
    font-weight: 700;
    margin-bottom: 10px;
  }
}
.action-buttons {
  // &:hover
  & > div svg {
    opacity: 0.2;
  }
  & > div:hover svg {
    opacity: 1;
  }
  & > div:hover .pencil-icon:hover path {
    fill: #008a60;
  }
  & > div:hover .trash-icon:hover path {
    fill: #af0505;
  }
  svg {
    cursor: pointer;
    rect {
      fill: none;
    }
  }
}
.main-tabs {
  background-color: #fff;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.5);
}
.admin-mode {
  position: absolute;
  margin: 11px;
  cursor: pointer;
  z-index: 9999;
  color: #0e87c3;
}
.react-multiple-carousel__arrow{
  background-color: #DB0A40;
  border: 6px solid #fff;
  width: 50px;
  height: 50px;
}